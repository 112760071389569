<template>
  <div class="cover page-slide">
    <div class="grid-2">
      <section>
        <h2 class="header-title mt-5">Payroll Services</h2>
        <p>
          We take away all your headaches for payroll processing and ensure you
          have the best of experience handling your process by delivering the
          highest possible standards in Payroll Outsourcing Services, and this
          has been possible due to our high levels of automation. To ensure
          accuracy, we execute a multi-level process check that completely
          mitigates any errors. Our process meets the highest quality service
          and achieves 100% accuracy – error-free.
        </p>

        <p>
          Our payroll management system is designed to keep your employees happy
          and satisfied. We ensure adherence to standards, government
          regulations, and tax regime. Employees of our clients are satisfied a
          lot, and we keep them informed with adequate information concerning
          salary, perquisites, taxes, provident fund, or any of the myriad parts
          of payroll processing.
        </p>
      </section>

      <img src="/payroll.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  text-align: justify;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-gap: 0 2rem;
}
@media (max-width: 768px) {
  p {
    font-size: 14px;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    grid-gap: 1rem 0m;
  }
}
img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}
</style>
