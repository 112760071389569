<template>
  <div class="cover page-slide">
    <h2 class="header-title mt-5">Staffing Solution</h2>
    <div class="grid-2">
      <img src="/staffing.jpg" alt="" />
      <section>
        <h4>1. Permanent Placement</h4>
        <p>
          Unlike most staffing agencies, we have a dedicated team of Direct Hire
          Consultants who specialize in targeted recruiting, sourcing and
          negotiating complex compensation packages for elite talent.  Our
          unique vetting process and extensive talent network ensure you’ll find
          talent with the technical chops and culture fit you need—FAST. We
          guarantee it!
        </p>

        <p>
          Our approach is to listen and fully understand how the role in
          question must fit in with your company persona. We ensure a &#39;deep
          dive&#39; into the values, cultures and strategic goals of your
          organization in addition to paying careful attention to each job
          specification.
        </p>

        <h4>2. Temporary Staff Solution</h4>
        <p>
          The Temporary Staffing Service offers applicants an opportunity to
          undertake interesting and varied temporary assignments within a wide
          range of Jobs. The Service offers flexibility, competitive rates of
          pay and the ability to gain valuable experience working for one of the
          emerging HR Consulting firms in India.
        </p>
      </section>
    </div>

    <p class="divider-p">
      Temporary staffing means, ATES provides you the human resources you need
      for a specified period. However, the employees continue to be on our
      payroll, and they work for you. We will take care of the rest like
      identifying the kind of employees you need and going through the hiring
      formalities and documentation. All you need to do is let them work for
      you. Once their job or requirement is over, you inform us, and we will
      take care of their exit. It is as simple as that.
    </p>

    <p>
      Temporary staffing is not limited to junior level but has touched senior
      level employees too.
    </p>

    <ul>
      <li>
        Looking for a way to reduce your administrative costs and liabilities
        associated with permanent employees?
      </li>
      <li>Looking to migrate employees from existing company rolls?</li>
      <li>
        Do you need human resources on a flexible period basis that can add to
        your margins, profits, and bottom line?
      </li>
      <li>
        Do you need human resources for particular positions or in groups?
      </li>
    </ul>
    <p class="mt-5">
      Talks to us and let ATES surprise you as to how temporary staffing can
      impact your business and finances directly.
    </p>

    <h4>3. Professional Staffing</h4>

    <p>
      When you have specific recruiting needs, use ATES professional staffing
      solution, it makes sense because you pay for what you need when you need
      it versus paying a full-time staff for seasonal or occasional recruiting.
      It is flexible to your business needs instead of being a fixed expense
      that is underutilized.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  text-align: justify;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-gap: 0 2rem;
}
.divider-p {
  margin-top: 20px;
}
@media (max-width: 768px) {
  p {
    font-size: 14px;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    grid-gap: 1rem 0m;
  }
  .divider-p {
    margin-top: 0;
  }
}
img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}
</style>
