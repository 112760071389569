<template>
  <div class="cover page-slide">
    <h2 class="header-title mt-5">Training & Development</h2>
    <div class="grid-2">
      <section>
        <p>
          ATES has been working with organizations of various sizes and
          industries across India in various verticals such as IT skills
          Training, Technical Training, and Management Training. ATES strongly
          believes in providing customized solutions specific to the unique
          requirements of our clients and aligning them with a unique range of
          high impact workshops
        </p>

        <h4>Our Approach Towards Training and Development Curriculums</h4>
        <p>
          Whenever possible, we offer training just in time to minimize the
          danger of learners forgetting what they’ve learned. This training
          method also improves retention by allowing learners to apply all they
          learned on the job right away.
        </p>
        <p>
          We tailor training for you and your company (rather than a
          pre-packaged solution that doesn’t fit your culture). We also work
          together to ensure that the training plan and processes meet your
          needs and expectations.
        </p>

        <p>
          As one of India’s leading training &amp; development companies, we
          understand that learning, change, and growth are not linear. Thus we
          provide supplementary reference materials and training manuals for
          your users to turn to in the future.
        </p>

        <p>
          Our training assessment gives you a complete picture of where you
          stand, where you want to go, and where you need to improve. This
          assessment is the primary source of information for your training
          plan. It is also used for other essential planning tasks like job role
          redesign, project risk reduction, and communication planning.
        </p>
      </section>
      <img src="/training.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  text-align: justify;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-gap: 0 2rem;
}
@media (max-width: 1100px) {
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    grid-gap: 1rem 0m;
  }
  img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    max-height: 100% !important;
  }
}
@media (max-width: 768px) {
  p {
    font-size: 14px;
  }
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 450px;
}
</style>
