<template>
  <div class="cover page-slide">
    <h2 class="header-title mt-5">Recruitment Process Outsourcing</h2>
    <p>
      Recruitment Process Outsourcing has become the most preferred mode for the
      organizations today i.e. Employer transfers all or part of its recruitment
      activities to an external vendor to control costs, be more effective and
      concentrate on their core-competencies. ATES experts RPO team works as the
      &quot;HR Manager&quot; of your organization and takes the responsibility
      to fulfil all the requirements. Our RPO team has diverse industry
      knowledge and expertise, are equipped with best tools and techniques to
      support in your Recruitment needs.
    </p>
    <div class="grid-2">
      <section>
        <ul>
          <li>
            Why Outsource Reduced Cost
            <p>
              Obviously one of the prime advantages of hiring us is its cost
              effectiveness. The bottom line is that we save companies money in
              the long run. We can scale up and scale down our recruitment
              activity to match the fluctuating hiring needs of the client. In
              business, to use the old adage, time is money. Every day that a
              position remains unfilled costs a company.
            </p>
          </li>
          <li>
            Better Quality of hires
            <p>
              This is the main purpose and advantage of us as the recruitment
              partner. We invest our time, energy and resources into sourcing,
              screening and presenting the cream of the crop in terms of
              available talent to our customers. The aggregated talent stream
              offers a cultivated pool from which to search. Referral activation
              is also a unique feature of ours.
            </p>
          </li>
          <li>
            Scalable Recruiting
            <p>
              You can match seasonal fluctuations, an awesome benefit of RPO is
              having a recruiting process in place that can scale up as per
              business requirement, and then scale back down at the beginning of
              the next year. RPO is also great for companies that are growing
              rapidly, that may need extra help in scaling their recruiting
              capacity to meet growth.
            </p>
          </li>
          <li>
            Talent Pooling
            <p>
              We as an outsourcing partner will always present the client with
              the best people for the job. All strong candidates across
              industries are pooled and actively engaged with, creating a
              community for future hiring
            </p>
          </li>
        </ul>
      </section>
      <img src="/rpo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  text-align: justify;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-gap: 0 2rem;
}
@media (max-width: 768px) {
  p {
    font-size: 14px;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    grid-gap: 1rem 0m;
  }
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
