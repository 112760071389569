<template>
  <div class="cover page-slide">
    <h2 class="header-title mt-5">Executive Search</h2>
    <div class="grid-2">
      <section>
        <h4>Because the World Needs Great Leaders</h4>
        <p>
          At ATES, we know what great leaders can do. We share one goal:
          Leadership for a better world. We do that by empowering our clients to
          drive lasting positive change through leadership. Each search
          engagement is customized to the specific objectives and dynamics of
          your needs.
        </p>
        <p>
          We deliver the power of the entire firm to every client, every time.
          We help companies build superior leadership teams at board and top
          management level positions like Managing Director, President, C level
          (CEOs, COOs, CFOs), Vice Presidents, Country Heads, and General
          Manager etc. for large and mid-size companies.
        </p>
        <p>
          Our executive recruiting consultants possess the expertise and
          contacts to best support our search. We draw on our high-level
          professional networks, industry knowledge and internal research
          resources to identify the right people. To stay on the cutting edge of
          talent strategy, we constantly track key trends in the global market
          for talent, and continually innovate our services and approach.
        </p>
      </section>

      <img src="/executive_search.png" alt="" />
    </div>

    <p class="mt-3">
      Our commitment to you does not end when we place a candidate. Success is
      not simply the placement but rather the long-term business impact a great
      leader can have. Our Accelerated Integration program goes far beyond
      onboarding by fully immersing the new hire into your business, team
      dynamics, and culture to get leaders off to their best start. As the
      journey continues, we can help to unlock the potential in individuals,
      teams, and organizations.
    </p>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
h4 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  text-align: justify;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-gap: 0 2rem;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  p {
    font-size: 14px;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    grid-gap: 1rem 0m;
  }
}
</style>
