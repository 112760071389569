<template>
  <div id="services">
    <div class="page-view">
      <StaffingSolution v-if="route_param == 1 || route_param == 'all'" />
      <PayrollServices v-if="route_param == 3 || route_param == 'all'" />
      <RPO v-if="route_param == 4 || route_param == 'all'" />
      <Training v-if="route_param == 5 || route_param == 'all'" />
      <ExecutiveSearch v-if="route_param == 2 || route_param == 'all'" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/UI/Footer.vue';
import StaffingSolution from '@/components/Services/StaffingSolution.vue';
import PayrollServices from '@/components/Services/PayrollServices.vue';
import RPO from '@/components/Services/RPO.vue';
import Training from '@/components/Services/Training.vue';
import ExecutiveSearch from '@/components/Services/ExecutiveSearch.vue';
export default {
  components: {
    StaffingSolution,
    Footer,
    Training,
    PayrollServices,
    RPO,
    ExecutiveSearch,
  },
  data: () => {
    return {};
  },
  computed: {
    route_param() {
      return this.$route.params.code;
    },
  },
};
</script>

<style scoped></style>
